import React from 'react';
import { navigate } from 'gatsby';

import Route from '@pages/app/route';

import '@styles/components/global.less';

const Index = ({ location }) => {
  if (location.pathname === `/`) {
    if (typeof window !== 'undefined') {
      navigate('/app/home');
    }
    return null;
  }

  return (
    <>
      <Route />
    </>
  );
};

export default Index;
